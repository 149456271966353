import { NgModule } from '@angular/core';
import {
    AdvancedSelectFolderComponent,
    BreadcrumbComponent, CfnEcmItemListComponent,
    CfnEcmItemGridComponent,
    CfnEcmItemDetailsComponent,
    SelectFolderComponent,
    SelectSortComponent,
    SelectSpaceComponent,
    ViewModeButtonComponent, NotificationsComponent, MetaDataFormComponent
} from './components';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogComponent, FolderDetailsDialogComponent } from './dialogs';
import { AddDocumentFolderDialogComponent } from './dialogs';
import { SharedModule, SmexLibCommonFrontendModule } from '@numen/smex-lib-common-frontend';
import { CfnEcmItemIconComponent } from './components';
import { LibCfnEcmCoreModule } from '../core/core.module';
import { AddMetaDataComponent } from './dialogs/add-metadata/add-metada.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { EditCustomMetadatasComponent } from './dialogs/edit-custom-metadatas/edit-custom-metadatas.component';


@NgModule({
    declarations: [
        CfnEcmItemIconComponent,
        CfnEcmItemListComponent,
        CfnEcmItemGridComponent,
        BreadcrumbComponent,
        CfnEcmItemDetailsComponent,
        FolderDetailsDialogComponent,
        ConfirmDialogComponent,
        ViewModeButtonComponent,
        SelectSpaceComponent,
        SelectSortComponent,
        SelectFolderComponent,
        AdvancedSelectFolderComponent,
        AddDocumentFolderDialogComponent,
        NotificationsComponent,
        MetaDataFormComponent,
        AddMetaDataComponent,
        FileSizePipe,
        EditCustomMetadatasComponent
    ],
    imports     : [
        SmexLibCommonFrontendModule,
        ReactiveFormsModule,
        SharedModule,
        LibCfnEcmCoreModule
    ],
    exports     : [
        SmexLibCommonFrontendModule,
        SharedModule,
        CfnEcmItemIconComponent,
        CfnEcmItemListComponent,
        CfnEcmItemGridComponent,
        BreadcrumbComponent,
        CfnEcmItemDetailsComponent,
        FolderDetailsDialogComponent,
        ConfirmDialogComponent,
        ViewModeButtonComponent,
        SelectSortComponent,
        SelectSpaceComponent,
        SelectFolderComponent,
        NotificationsComponent,
        MetaDataFormComponent,
        FileSizePipe,
        EditCustomMetadatasComponent
    ]
})
export class LibCfnEcmSharedModule {
}
