import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';

import { AuthorizationFrontendService, UserFrontendService } from '@numen/smex-lib-common-frontend' ;
import { CfnEcmFrontConfigurationService } from './cfnecmfront-configuration.service';
import { UserSpace } from '../models';

@Injectable({
    providedIn: 'root'
})
export class CfnEcmUserService {
    private static AVAILABLE_SPACES = 'AVAILABLE_SPACES';
    private static LAST_USED_SPACE_KEY = 'LAST_USED_SPACE_KEY';

    public actionInProgress: boolean = false;

    constructor(
        private userService: UserFrontendService,
        private m_AuthorizationService: AuthorizationFrontendService,
        private m_ConfigurationService: CfnEcmFrontConfigurationService) {
    }

    public setUserSpaces(spaces: UserSpace[]) {
        window.localStorage.setItem(CfnEcmUserService.AVAILABLE_SPACES, JSON.stringify(spaces));
    }

    public getUserSpaces(): UserSpace[]|null {
        const userData = this.userService.userData as any;
        if (Array.isArray(userData?.securedSpaces)) {
            return userData?.securedSpaces as UserSpace[];
        }
        return null;
    }

    /**
     * Get list of spaces.
     *
     * @returns observable of space.
     */
    public getAvailableSpaces(): Observable<UserSpace[]> {
        let URI: string = this.m_ConfigurationService.getCfnEcmApiUri('/spaces/available');
        return from(this.m_AuthorizationService.get<UserSpace[]>(URI));
        // const spaces = await this.m_AuthorizationService.get<Space[]>(URI);
        // this.setAvailableSpaces(spaces);
        // return spaces;
    }

    /**
     * Get last used space.
     */
    public get lastUsedSpace(): UserSpace | null {
        const space = window.localStorage.getItem(CfnEcmUserService.LAST_USED_SPACE_KEY);
        return space ? JSON.parse(space) : null;
    }

    /**
     * Set current space.
     *
     * @param space space.
     */
    public setLastUsedSpace(space: UserSpace): void {
        window.localStorage.setItem(CfnEcmUserService.LAST_USED_SPACE_KEY, JSON.stringify(space));
    }

    public close() {
        window.localStorage.removeItem(CfnEcmUserService.LAST_USED_SPACE_KEY);
        window.localStorage.removeItem(CfnEcmUserService.AVAILABLE_SPACES);
    }

}
